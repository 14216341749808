@keyframes fade-in {
  from {
    opacity: 0;
  }
    to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.page__wrapper .wrapper.awards {
  margin: 140px auto 0;
}

@media screen and (max-width:  600px) {
  .page__wrapper .wrapper.awards {
    margin: 80px auto 0;
  }
  
}

.awards {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  max-width: 1440px;
  margin: 0 auto;
  background-color: var(--primary-bg-color);
  border-radius: 10px;
}

.awards__title-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto 60px 0;
  font: var(--font-h-l);
  text-align: center;
  color: var(--color-white);
}

.awards__title1 {
  opacity: 0;
  display: inline-block;
  font-weight: inherit;
}

.awards__title2 {
  color: var(--color-accent);
  display: inline-block;
  margin-left: 10px;
  font-weight: inherit;
}

@media screen and (max-width:  600px) {
  .awards__title-wrapper {
    margin: 0 auto 60px 0;
  }
  .awards__title1 {
    margin: 10px 0 0;
  }
  .awards__title2.animation__text-rise {
    margin: 0 0 0 20px;
    animation-delay: 1.5s;
  }
}

.awards__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  width: 100%;
}

.awards__wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: min-content;
  gap: 20px;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .awards__wrapper {
    grid-template-columns: repeat(auto-fit, 1fr); /* Грид-сетка на промежуточных экранах */
    gap: 15px;
  }
}

@media screen and (max-width:  600px) {
  .awards__wrapper {
    gap: 5px;
  }
}

.awards__item {
  width: 100%;
  max-width: 150px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;

  opacity: 0;
  transform: scale(0.95) translateY(20px);
  animation: fade-in 1s ease-out forwards;
  padding: 20px;
  border-radius: 10px;
}

.awards__item.visible {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.awards__item.hidden {
  display: none;
}

@media screen and (max-width:  600px) {
  .awards__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .awards__item {
    padding: 0;
    animation: fadeInOut 3s ease-out forwards;
  }
}

.awards__img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.awards__text {
  margin-top: 10px;
  color: var(--color-grey);
  font: var(--font-l);
}

@media screen and (max-width:  600px) {
  .awards__img {
    width: 60%;
  }
  .awards__text {
    font: var(--font-m);
  }
  
}

.awards__itemTitle {
  
  margin: 10px 0 5px;
  color: var(--color-white);
}

.awards__itemSubtitle {
  font: var(--font-m);
  color: var(--color-grey);
}

@media screen and (max-width:  600px) {
  .awards__itemSubtitle {
    font: var(--font-m);
    color: var(--color-grey);
    text-decoration: underline;
    word-wrap: max-content;
    white-space: nowrap;
  }
}
